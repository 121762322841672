define(['jquery', 'siteObj', 'fancyBox', 'common'], function($, siteObj) {
  var component = {};
  /// TEXTAREA VALIDATION
  var characterLimit = 700;
  // var tval;
  // var tlength;
  // var set;
  var limitReachedOk = true;

  var user_input = 'false';
  var am_i_active = 'false';
  var recommend_text = '<p class=\'recommend_text\'>Recommended</p>';
  var telephone_number = 'cs';
  // var comment_info;
  // var last2Chars;
  // var callToCancel;
  var existingDropDownValue;
  var fourTiers = 'false';
  var checkbox_alert = 'false';
  var selectbox_val_1;
  var selectbox_val_2;
  var selectbox_val_3;
  var previousOrderSelected = true;
  var somethingElseSelected = false;

  var push_data_to_gaEventTracking = function(linkpageName, linkSectionName, linkTextName) {
    linkTextName = linkTextName.replace(/ /g, '-').toLowerCase();
    var galinkTextName = linkSectionName + '_' + linkTextName;
    var galinkPageName = 'progand_' + linkpageName;
    siteObj.fn.gaEventTrack(galinkPageName, 'click', galinkTextName);
  };

  var push_data_to_gaEventTracking_select = function(selectPageName, selectSectionName, selectTextName) {
    selectTextName = selectTextName.replace(/ /g, '-').toLowerCase();
    var gaselectTextName = selectSectionName + '_' + selectTextName;
    var gaselectPageName = 'progand_' + selectPageName;
    siteObj.fn.gaEventTrack(gaselectPageName, 'select', gaselectTextName);
  };

  // var select_option = function(selectbox, option) {
  //   $('#' + selectbox + '').val(option);

  // };
  // var remain;
  var inputbox_characterCount = function(e) {
    var tval = $('textarea').val();
    var tlength = tval.length;
    var set = characterLimit;
    var remain = parseInt(set - tlength);
    $('.char-count').text(remain);
    if (remain <= 0 && e.which !== 0 && e.charCode !== 0) {
      $('textarea').val((tval).substring(0, tlength - 1));
    }
    if (remain <= 10) {
      $('.char-count').css({
        color: '#f00'
      });
    } else {
      $('.char-count').removeAttr('style');
    }
    limitReachedOk = true;
  };
  //Call character count function
  $('.js-gandalf-customer-message').keyup(function() {
    inputbox_characterCount();
  });

  var email_active = function() {
    am_i_active = 'true';
    $('#info-alpha').removeClass('inactive').addClass('active');
    $('#info-alpha .js-send-message').removeClass('disabled').removeAttr('disabled');
    $('#info-beta').removeClass('active').addClass('inactive');
    $('#info-beta .js-info-button').removeClass('disabled');
    $('#info-charlie .lp-disabled-btn').addClass('disabled-btn');
    $('#info-charlie .lp-btn').removeClass('disabled-btn');
    $('#info-alpha .set-of-3').append(recommend_text);

  };

  var phone_active = function() {
    am_i_active = 'true';
    $('#info-alpha').removeClass('active').addClass('inactive');
    $('#info-alpha .js-send-message').removeClass('disabled').removeAttr('disabled');
    $('#info-beta').removeClass('inactive').addClass('active');
    $('#info-beta .js-info-button').removeClass('disabled');
    $('#info-charlie .lp-disabled-btn').addClass('disabled-btn');
    $('#info-charlie .lp-btn').removeClass('disabled-btn');
    $('#info-beta .set-of-3').append(recommend_text);

  };

  var show_fileuploader = function() {
    if (!$('.file-uploader').hasClass('show')) {
      $('.file-uploader').addClass('show');
    }
  };

  var hide_fileuploader = function() {
    if ($('.file-uploader').hasClass('show')) {
      $('.file-uploader').removeClass('show');
    }
  };


  var deactivate_all = function() {
    am_i_active = 'false';
    $('#info-alpha').removeClass('active').addClass('inactive');
    $('#info-beta').removeClass('active').addClass('inactive');
    $('#info-charlie').removeClass('active').addClass('inactive');
    $('#info-charlie .disabled-link').show();
    $('.set-of-3 .btn').addClass('disabled');
    $('#info-charlie .lp-btn').addClass('disabled-btn');
    $('#info-charlie .lp-disabled-btn').removeClass('disabled-btn');
    $('.set-of-3 input.btn').prop('disabled', 'true');
    $('.set-of-3 .recommend_text').remove();
    $('.js-gandalf-popover h5').html('You have chosen to phone us.');
    $('p.intro-paragraph strong').html(siteObj.csTelephoneNumber);
    $('.js-gandalf-popover .size-2of5').show();
    checkboxChecking();
  };

  var remove_marker = function() {
    $('.select-label1').removeClass('this-step');
    $('.select-label2').removeClass('this-step');
    $('.select-label3').removeClass('this-step');
    $('.select-label4').removeClass('this-step');
  };

  var level1_marker = function() {
    $('.select-label1').addClass('this-step');
    $('.select-label2').removeClass('this-step');
    $('.select-label3').removeClass('this-step');
    $('.select-label4').removeClass('this-step');
  };

  var level2_marker = function() {
    $('.select-label1').removeClass('this-step');
    $('.select-label2').addClass('this-step');
    $('.select-label3').removeClass('this-step');
    $('.select-label4').removeClass('this-step');
  };

  var level3_marker = function() {
    $('.select-label1').removeClass('this-step');
    $('.select-label2').removeClass('this-step');
    $('.select-label3').addClass('this-step');
    $('.select-label4').removeClass('this-step');
  };

  var level4_marker = function() {
    $('.select-label1').removeClass('this-step');
    $('.select-label2').removeClass('this-step');
    $('.select-label3').removeClass('this-step');
    $('.select-label4').addClass('this-step');
  };

  var clear_comments = function() {
    $('.nothing-selected').hide();
    $('.js-did-you-know').hide();
    $('p.recommend_text').hide();
    $('.js-gandalf-popover').hide();
  };

  var hide_comment_section = function() {
    $('#contact-us').hide();
  };

  var remove_selectboxes = function() {
    $('.option2').hide();
    $('.option3').hide();
    $('.option4').hide();
    $('.js-did-you-know').hide();
    hide_fileuploader();
  // callToCancel = '';
  };

  // var which_items = function() {
  //   var val = [];
  //   $(':checkbox:checked').each(function(i) {
  //     val[i] = $(this).val();
  //   });
  // };

  var hide_did_you_know = function() {
    $('#estimated_date_text').hide();
    $('#not_received_text').hide();
    $('#dyk_auto_track').hide();
    $('#dyk_cancel_item').hide();
    $('#cancel_item_text').hide();
    $('#dyk_pricebeater').hide();
    $('#dyk_nutritionalist').hide();
    $('#dyk_delivery_options').hide();
    $('#dyk_offers').hide();
    $('#dyk_thg_site').hide();
    $('#dyk_when_despatched').hide();
    $('#dyk_return_damaged').hide();
    $('#dyk_return_faulty').hide();
    $('#when_receive_refund').hide();
    $('#dyk_payment_problems').hide();
    $('#dyk_discount_code').hide();
    $('#dor_download').hide();
    $('.js-did-you-know').hide();
  };

  function didYouKnow(id) {
    $('.js-did-you-know').show();
    $('#' + id).show();
  }

  function resetDropDown(level2, level3, level4) {
    if (level2 === true) {
      $('.option2 select').val(0);
    }
    if (level3 === true) {
      $('.option3 select').val(0);
    }
    if (level4 === true) {
      $('.option4 select').val(0);
    }
  }

  //adding the miami path to the dropdown to pass to selenium
  function insertMiamiPath(element, miamiValue) {
    $(element).attr('data-miami', miamiValue);
  }

  // An Order I Placed - Click
  $('.js-previous-order').on('click', function() {
    somethingElseSelected = false;
    previousOrderSelected = true;
    window.location.href = window.location.href.split('?')[0];
    remove_selectboxes();
    deactivate_all();
    hide_did_you_know();
    $(this).removeClass('inactive').addClass('active');
    $('.js-something-else').removeClass('active').addClass('inactive');
    $('.path.else').hide();
    $('.path.order').show();
    $('.help .path.order').show();
    $('.select-label1').addClass('this-step');
    $('.js-tell-us-more').show();
    push_data_to_gaEventTracking('account', 'Section1', 'Order');
  });
  // Something Else - Click
  $('.js-something-else').on('click', function() {
    somethingElseSelected = true;
    previousOrderSelected = false;
    remove_selectboxes();
    deactivate_all();
    $('.else p.btn:nth-child(3)').nextAll('p.btn').hide();
    $('.nothing-selected').hide();
    $(this).removeClass('inactive').addClass('active');
    $('.js-previous-order').removeClass('active').addClass('inactive');
    $('.path.order').hide();
    $('.path.else').show();
    $('.select-label1').addClass('this-step');
    $('.js-tell-us-more').show();
    push_data_to_gaEventTracking('account', 'Section1', 'Something else');
  });

  $('.path.outer.else a').on('click', function() {
    var linkTextName = $(this).text(); // this area sets the tracking codes for the Cat links
    push_data_to_gaEventTracking('account', 'Section1 | Else - Button :', linkTextName);
  });

  $('.path.outer.order a').on('click', function() {
    var linkTextName = $(this).text(); // this area sets the tracking codes for the Cat links
    push_data_to_gaEventTracking('account', 'Section1 | Order - Button :', linkTextName);
  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------     PATH 1      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path1', function(e) {

    existingDropDownValue = '';
    $('.option2').hide();
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(true, true, true);
    hide_did_you_know();
    hide_fileuploader();

    if ($(e.target).find(':selected').val() === '1') {
      deactivate_all();
      clear_comments();
      $('#p1-option2a').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '2') {
      deactivate_all();
      clear_comments();
      $('#p1-option2b').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '3') {
      deactivate_all();
      clear_comments();
      $('#p1-option2c').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '4') {
      deactivate_all();
      clear_comments();
      $('#p1-option2d').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '5') {
      deactivate_all();
      clear_comments();
      $('#p1-option2e').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level1_marker();
      hide_did_you_know();

    }
    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown1 :', selected_option_text);


  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------      ALPHA      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path1alpha', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_did_you_know();
    hide_fileuploader();

    //put the breaks in here
    if ($(e.target).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_when_despatched');
      level3_marker();
      email_active();
      existingDropDownValue = 63;
      insertMiamiPath('select#path1alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      level3_marker();
      email_active();
      existingDropDownValue = 54;
      insertMiamiPath('select#path1alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      $('#p1-option3a').show();
      level3_marker();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '4') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dor_download');

      level3_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 64;
      insertMiamiPath('select#path1alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '5') {
      clear_comments();
      deactivate_all();
      $('#p1-option3b').show();
      level3_marker();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '6') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      level3_marker();
      email_active();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown2 :', selected_option_text);

  });

  $('body').on('change', '#path1alpha2_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_did_you_know();
    hide_fileuploader();

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('not_received_text');
      level4_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 64;
      insertMiamiPath('select#path1alpha2_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_auto_track');
      level4_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 63;
      insertMiamiPath('select#path1alpha2_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      remove_marker();
      hide_did_you_know();
      selectbox_val_3 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });

  $('body').on('change', '#path1alpha5_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('not_received_text');
      level4_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 69;
      insertMiamiPath('select#path1alpha5_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_auto_track');
      level4_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 69;
      insertMiamiPath('select#path1alpha5_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      remove_marker();
      hide_did_you_know();
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });


  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------       BETA      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/
  var damagedItemFlag = 0;

  $('body').on('change', '#path1beta', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      $('#p1-option3c').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();
    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      $('#p1-option3d').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();
    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      $('#p1-option3b').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();
    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      deactivate_all();
      $('#p1-option3e').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();
    } else if ($(this).find(':selected').val() === '5') {
      clear_comments();
      deactivate_all();
      $('#p1-option3f').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();
    } else if ($(this).find(':selected').val() === '6') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      didYouKnow('dyk_return_faulty');
      level3_marker();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '7') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      email_active();
      level3_marker();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown2 :', selected_option_text);

  });

  $('body').on('change', '#path1beta1_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_damaged');
      email_active();
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_damaged');
      email_active();
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_damaged');
      email_active();
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';


    } else {
      clear_comments();
      deactivate_all();
      level3_marker();
      hide_did_you_know();
      selectbox_val_3 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });


  $('body').on('change', '#path1beta2_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta2_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta2_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1beta2_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level3_marker();
      hide_did_you_know();

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });

  $('body').on('change', '#path1beta4_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      email_active();
      level4_marker();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta4_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      email_active();
      level4_marker();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta4_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level3_marker();
      hide_did_you_know();

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });

  $('body').on('change', '#path1beta5_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      level4_marker();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta5_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      level4_marker();
      existingDropDownValue = 69;
      insertMiamiPath('select#path1beta5_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level3_marker();
      hide_did_you_know();
      selectbox_val_3 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);

  });


  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------     CHARLIE     ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path1charlie', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      $('#p1-option3g').show();
      level3_marker();
      existingDropDownValue = 54;
      insertMiamiPath('select#path1charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      $('#p1-option3g').show();
      level3_marker();
      existingDropDownValue = 54;
      insertMiamiPath('select#path1charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level2_marker();
      existingDropDownValue = 54;
      insertMiamiPath('select#path1charlie', existingDropDownValue);
      hide_did_you_know();
      selectbox_val_2 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown2 :', selected_option_text);

  });


  $('body').on('change', '#path1charlie1_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() !== '0') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      didYouKnow('cancel_item_text');
      email_active();
      level4_marker();
      existingDropDownValue = 54;
      insertMiamiPath('select#path1charlie1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level3_marker();
      hide_did_you_know();
      selectbox_val_3 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 | Order - Dropdown3 :', selected_option_text);
  });


  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------      DELTA      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path1delta', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      $('#p1-option3h').show();
      level3_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2' || $(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('when_receive_refund');
      email_active();
      level4_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      $('#filters .inner-content').remove('.select-label3');
      clear_comments();
      deactivate_all();
      level2_marker();
      existingDropDownValue = 61;
      //insertMiamiPath('select#path1delta',existingDropDownValue);
      hide_did_you_know();
      selectbox_val_2 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Order - Dropdown2 -', selected_option_text);

  });

  $('body').on('change', '#path1delta1_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';
    if($('#actionValue').val() == 'return') {
      $('.customerQuery_return_button_container').addClass('hidden');
      $('#product-return-list').addClass('hidden');
      $('#product-list').show();
      $('.customerQuery_bodyText').show();
      selectbox_val_1 = 'Issue:\n' + $('.select-box1 option:selected').data('element') + '\n';
      selectbox_val_2 = $('.select-box3 option:selected').data('element') + '\n';
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';
    }

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_faulty');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      if($('#actionValue').val() == 'return') {
        //Display 'Start my return form' button and hide contact us flow
        $('.customerQuery_return_button_container').removeClass('hidden');
        $('#product-return-list').removeClass('hidden');
        $('#product-list').hide();
        $('.customerQuery_bodyText').hide();
        $('.option4').hide();
      } else {
        show_fileuploader();
        $('.option4').show();
        email_active();
      }
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_return_damaged');
      email_active();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      email_active();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta1_1', existingDropDownValue);
      selectbox_val_3 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      $('#filters .inner-content').remove('.select-label3');
      clear_comments();
      deactivate_all();
      level2_marker();
      existingDropDownValue = 61;
      insertMiamiPath('select#path1delta1_1', existingDropDownValue);
      hide_did_you_know();
      selectbox_val_3 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Order - Dropdown3 -', selected_option_text);

  });


  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------       ECHO      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path1echo', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 58;
      insertMiamiPath('select#path1echo', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_payment_problems');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 58;
      insertMiamiPath('select#path1echo', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      show_fileuploader();
      $('.option4').show();
      didYouKnow('dyk_discount_code');
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 57;
      insertMiamiPath('select#path1echo', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      deactivate_all();
      $('#path1echo4_1').prop('checked', false);
      $('#p1-option3i').show();
      level3_marker();

      existingDropDownValue = 70;
      insertMiamiPath('select#path1echo', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      clear_comments();
      deactivate_all();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Order - Dropdown2 -', selected_option_text);

  });

  $('body').on('change', '#path1echo4_1', function(e) {
    $('.option4').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    clear_comments();
    existingDropDownValue = 70;

    var selected_option_text = $(e.target).data('element');

    if ($(this).prop('checked')) {
      $('.option4').show();
      hide_comment_section();
      var order_number = siteObj.mvt.ordernumber.value;
      $('#comment').val('VAT Receipt requested for Order Number: ' + order_number);
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }

      insertMiamiPath('select#path1echo4_1', existingDropDownValue);
      selected_option_text += ' (checked)';
    } else {
      deactivate_all();
      level3_marker();

      existingDropDownValue = 70;
      insertMiamiPath('select#path1echo', existingDropDownValue);

      selected_option_text += ' (unchecked)'
    }

    push_data_to_gaEventTracking_select('account', 'Section2 : Order - Checkbox3 -', selected_option_text);

  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------     PATH 2     ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path2', function(e) {

    $('.option2').hide();
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(true, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(e.target).find(':selected').val() === '1') {
      deactivate_all();
      clear_comments();
      $('#p2-option2a').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '2') {
      deactivate_all();
      clear_comments();
      $('#p2-option2b').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '3') {
      deactivate_all();
      clear_comments();
      $('#p2-option2c').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else if ($(e.target).find(':selected').val() === '4') {
      deactivate_all();
      clear_comments();
      $('#p2-option2d').show();
      level2_marker();
      selectbox_val_1 = 'Issue:\n' + $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level1_marker();
      hide_did_you_know();
      selectbox_val_1 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Else - Dropdown1 -', selected_option_text);

  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------      ALPHA      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path2alpha', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }

      existingDropDownValue = 60;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 67;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 59;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 57;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '5') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 62;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '6') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 59;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '7') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      email_active();
      existingDropDownValue = 60;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '8') {
      clear_comments();
      deactivate_all();
      $('.option4').show();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      existingDropDownValue = 57;
      insertMiamiPath('select#path2alpha', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';

    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Else - Dropdown2 -', selected_option_text);

  });


  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------       BETA      ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path2beta', function(e) {
    $('.option3').hide();
    resetDropDown(false, false, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      email_active();
      $('.option4').show();
      didYouKnow('dyk_pricebeater');
      show_fileuploader();
      existingDropDownValue = 66;
      insertMiamiPath('select#path2beta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      email_active();
      $('.option4').show();
      show_fileuploader();
      existingDropDownValue = 66;
      insertMiamiPath('select#path2beta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Else - Dropdown2 -', selected_option_text);

  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------     CHARLIE     ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path2charlie', function(e) {
    $('.option3').hide();
    $('.option4').hide();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      if ((siteObj.features.projectGandalf.lifestyle === true || siteObj.features.projectGandalf.prestige === true) && siteObj.features.projectGandalf.phone === true) {
        phone_active();
      } else {
        email_active();
      }
      $('.option4').show();
      show_fileuploader();
      existingDropDownValue = 62;
      insertMiamiPath('select#path2charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      email_active();
      $('.option4').show();
      show_fileuploader();
      existingDropDownValue = 65;
      insertMiamiPath('select#path2charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      email_active();
      $('.option4').show();
      existingDropDownValue = 57;
      insertMiamiPath('select#path2charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      email_active();
      $('.option4').show();
      didYouKnow('dyk_thg_site');
      existingDropDownValue = 15;
      insertMiamiPath('select#path2charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else if ($(this).find(':selected').val() === '5') {
      clear_comments();
      email_active();
      $('.option4').show();
      didYouKnow('dyk_thg_site');
      existingDropDownValue = 15;
      insertMiamiPath('select#path2charlie', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';

    } else {
      deactivate_all();
      clear_comments();
      level2_marker();
      hide_did_you_know();
      selectbox_val_2 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Else - Dropdown2 -', selected_option_text);

  });

  /*------------------------------------------------------------------------------
   /*------------------------------------------------------------------------------
   /*----------------------------     DELTA     ---------------------------------
   /*------------------------------------------------------------------------------
   ------------------------------------------------------------------------------*/

  $('body').on('change', '#path2delta', function(e) {
    $('.option3').hide();
    $('.option4').show();
    resetDropDown(false, true, true);
    hide_fileuploader();
    hide_did_you_know();
    deactivate_all();
    existingDropDownValue = '';

    if ($(this).find(':selected').val() === '1') {
      clear_comments();
      email_active();
      existingDropDownValue = 65;
      insertMiamiPath('select#path2delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();

    } else if ($(this).find(':selected').val() === '2') {
      clear_comments();
      email_active();
      existingDropDownValue = 65;
      insertMiamiPath('select#path2delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();

    } else if ($(this).find(':selected').val() === '3') {
      clear_comments();
      email_active();
      existingDropDownValue = 65;
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();

    } else if ($(this).find(':selected').val() === '4') {
      clear_comments();
      email_active();
      existingDropDownValue = 65;
      insertMiamiPath('select#path2delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();

    } else if ($(this).find(':selected').val() === '5') {
      clear_comments();
      email_active();
      existingDropDownValue = 65;
      insertMiamiPath('select#path2delta', existingDropDownValue);
      selectbox_val_2 = $(e.target).find(':selected').data('element') + '\n';
      level3_marker();

    } else {
      deactivate_all();
      clear_comments();
      level3_marker();
      hide_did_you_know();
      selectbox_val_2 = '';
    }

    var selected_option_text = $(e.target).find(':selected').data('element');
    push_data_to_gaEventTracking_select('account', 'Section2 : Else - Dropdown2 -', selected_option_text);

  });

  var checkboxChecking = function(checkbox_alert) {
    checkbox_alert = false;
    return false;
  };

  var checkboxOnlyAlerting = function(checkbox_alert) {
    alert('Please use the check boxes to select the item(s) this query relates to.');
    checkbox_alert = true;
    return false;
  };

  var checkboxAndUploadAlerting = function(checkbox_alert) {
    alert('Please use the check boxes to select the item(s) this query relates to and upload a photo of the damaged item.');
    $('.file-uploader').css({
      'border': '2px red solid',
      'padding-top': '20px',
      'background': '#fffafa'
    });
    checkbox_alert = true;
    return false;
  };

  $('[data-js-element="myAccountReturnsCheck_productCheckbox"]').on('click', function() {
    checkboxChecking();
  });

  $('.file-uploader').on('click', function() {
    $('.file-uploader').css({
      'border': 'none',
      'background': '#fff'
    });
  });


  $('.js-send-message').on('click', function(e) {

    e.preventDefault();

    var file_uploaded = $('#fileToken').val();

    if (($('input[data-js-element="myAccountReturnsCheck_productCheckbox"]').is(':checked') || somethingElseSelected) && limitReachedOk === true) {
      var items = '';
      var item_numbers = '';
      var message_intro = '';

      $('[data-js-element="myAccountReturnsCheck_productCheckbox"]:checked').each(function() {
        var values = $(this).val() + ',';
        item_numbers += values;
        items = 'Order items: \n' + item_numbers + '\n\n';
      });

      $(e.target).val().replace('>', '');
      var order_number = siteObj.mvt.ordernumber.value + '\n\n';

      if (typeof selectbox_val_3 !== 'undefined') {
        fourTiers = 'true';
      }

      var inputbox_val = $('.js-gandalf-customer-message').val();
      if (typeof inputbox_val === 'undefined') {
        inputbox_val = '\n';
      } else {
        inputbox_val = 'Customer info:\n' + inputbox_val + '\n\n';
        user_input = 'true';
      }

      if (file_uploaded) {
        file_uploaded = 'File Uploaded: Y \n';
      } else {
        file_uploaded = '';
      }

      // var path_name = push_data_to_gaEventTracking('account', 'Section3', contact_txt);

      if (previousOrderSelected) {
        message_intro = 'Order number: ' + order_number + items;
      } else {
        message_intro = 'I have an issue with something else. \n\n';
      }

      var email_comment_info = '';

      if (user_input === 'false') {
        email_comment_info = message_intro + file_uploaded + selectbox_val_1 + selectbox_val_2 + selectbox_val_3;
      } else {
        if (fourTiers === 'true') {
          email_comment_info = message_intro + file_uploaded + selectbox_val_1 + selectbox_val_2 + selectbox_val_3 + inputbox_val;
        } else {
          email_comment_info = message_intro + file_uploaded + selectbox_val_1 + selectbox_val_2 + inputbox_val;
        }
      }

      if (am_i_active === 'false') {
        $('.nothing-selected').show();
      } else {
        $('.js-gandalf-popover').hide();
        $('#comment').val(email_comment_info);
        $('#miami-option').val(existingDropDownValue);
        $('#CustomerQueryForm-BS-SOM').submit();
      /* SUBMITS THE FORM */
      }

    } else if ($('input[data-js-element="myAccountReturnsCheck_productCheckbox"]').not(':checked') && damagedItemFlag === 1 && file_uploaded === '') {
      checkboxAndUploadAlerting(checkbox_alert);
      return false;
    } else {
      checkboxOnlyAlerting(checkbox_alert);
      return false;
    }
  });


  $('#info-beta .js-info-button').on('click', function(e) {
    var items = '';
    var item_numbers = '';
    var message_intro = '';

    $('[data-js-element="myAccountReturnsCheck_productCheckbox"]:checked').each(function() {
      var values = $(this).val() + ',';
      item_numbers += values;
      items = '<strong>Order items:</strong><br />' + item_numbers + '<br /><br />';
    });

    $(e.target).val().replace('>', '');
    var order_number = siteObj.mvt.ordernumber.value + '<br /><br />';
    var selectbox_val_1 = $('.select-box1 option:selected').data('element');
    selectbox_val_1 = '<strong>Issue selected:</strong> ' + selectbox_val_1 + '<br />';
    var selectbox_val_2 = $('.select-box2 option:selected').data('element');
    selectbox_val_2 = '<strong>Issue details:</strong> ' + selectbox_val_2 + '<br />';
    var selectbox_val_3 = $('.select-box3 option:selected').data('element');
    selectbox_val_3 = '<strong>Additional details:</strong> ' + selectbox_val_3 + '<br /><br />';
    var inputbox_val = $('.js-gandalf-customer-message').val();
    inputbox_val = '<strong>Customer added details:</strong><br />' + inputbox_val + '<br /><br />';
    var file_uploaded = $('#fileToken').val();

    if (file_uploaded) {
      file_uploaded = 'File Uploaded: YES';
    } else {
      file_uploaded = '';
    }

    // var path_name = push_data_to_gaEventTracking('account', 'Section3', contact_txt);

    if (previousOrderSelected) {
      message_intro = 'Your issue is with order number: ' + order_number + items;
    } else {
      message_intro = 'I have an issue with something else.<br /><br />';
    }

    var phone_comment_info = '';

    if (user_input === 'false') {
      phone_comment_info = message_intro + selectbox_val_1 + selectbox_val_2 + selectbox_val_3;
    } else {
      if (fourTiers === 'true') {
        phone_comment_info = message_intro + selectbox_val_1 + selectbox_val_2 + selectbox_val_3 + inputbox_val;
      } else {
        phone_comment_info = message_intro + selectbox_val_1 + selectbox_val_2 + inputbox_val;
      }
    }

    if (am_i_active === 'false') {
      $('.nothing-selected').show();
    } else {
      if (telephone_number === 'nutrition') {
        $('.js-gandalf-popover').show();
        $('.js-gandalf-popover h5').html('You have chosen to speak to one of our nutritionalists.');
        $('.js-gandalf-popover .size-2of5').hide();

      } else {
        if (previousOrderSelected) {
          $('.js-gandalf-popover').show();
          $('.js-gandalf-popover .inner p.query-data').html(phone_comment_info);

        } else {
          $('.js-gandalf-popover').show();
          $('.js-gandalf-popover .size-2of5').hide();
        }
      }
      telephone_number = 'cs';
    }
  });

  //CHARLIE IS HANDLED BY LIVE PERSON

  $('#account.bsam-contact').each(function() {
    previousOrderSelected = true;
    somethingElseSelected = false;
    $('.nothing-selected').hide();
    $('.js-previous-order').removeClass('inactive').addClass('active');
    $('.js-something-else').removeClass('active').addClass('inactive');
    $('.else p.btn:nth-child(3)').nextAll('p.btn').hide();
    $('.path.else').hide();
    $('.path.order').show();
    $('.option2').hide();
    $('.option3').hide();
    $('.option4').hide();
    hide_did_you_know();
    $('.file-uploader').removeClass('show');
    $('.select-box1').prop('disabled', true).addClass('disabled');
    push_data_to_gaEventTracking('account', 'Section1 | Order - Button :', 'An order I placed');
  });

  if (!siteObj.mvt.ordernumber.value) {
    $('.path .order-line').show();
    $('.path .order-title').show();
  } else {
    $('.path .order-line').hide();
    $('.path .order-title').hide();
    $('.select-box1').prop('disabled', false).removeClass('disabled');
    $('.select-label1').addClass('this-step');
  }

  $('.js-send-message').keypress(function(e) {
    var charCode = e.charCode || e.keyCode || e.which;
    if (charCode === 13) {
      return false;
    }
  });

  $('.js-open-gandalf-popover').on('click', function() {
    $('.js-gandalf-popover').show();
  });

  $('.js-close-gandalf-popover').on('click', function() {
    $('.js-gandalf-popover').hide();
    return;
  });
  return component;
});
